<template>
  <section class="content content--blue landing">
    <div class="content__wrapper">
      <figure ref="logo" class="landing__logo">
        <img
          class="landing__image"
          alt="animated logo"
          :src="'/images/BRC-home-logo.svg?' + new Date().getTime()"
        />
        <legend class="content__header landing__header">
          Business Resilience Calculator
        </legend>
      </figure>
      <div class="content__controls landing__buttons">
        <nuxt-link v-if="!this.$store.state.user.authenticatedUser" to="/login">
          <button class="button button--rounded landing__login">Login</button>
        </nuxt-link>
        <nuxt-link v-else :to="continueWhere">
          <button class="button button--rounded landing__login">
            Continue
          </button>
        </nuxt-link>
        <nuxt-link
          v-if="!this.$store.state.user.authenticatedUser"
          to="/login"
          @click.native="setCreateAccount"
        >
          <button class="button landing__create-account">Create Account</button>
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script>
import consola from "consola";

export default {
  name: "WelcomePage",
  middleware: "close-nav",
  mounted() {
    // consola.info(
    //   "Were cookies accepted " + this.$store.state.user["cookie:accepted"]
    // );
  },
  computed: {
    loginText() {
      if (this.$store.state.user?.authenticatedUser) {
        return "Continue";
      }
      return "Login";
    },
    // This nonsense needs to get the most accurate stuff for a user...
    continueWhere() {
      consola.info(
        `continueWhere results=${this.$store.state.results?.length}`
      );
      const scenarioResult = this.$store.getters.scenarioData;
      consola.info("Any scenarioResult?: ", scenarioResult);
      consola.info("What about the acceptTerms? ", scenarioResult.acceptTerms);
      if (scenarioResult.acceptTerms != true) {
        return "/user-agreement";
      } else if (
        !scenarioResult.businessCategory &&
        !scenarioResult.employeeCount
      ) {
        return "/info";
      } else if (!scenarioResult.scenario) {
        return "/scenario";
      } else if (!scenarioResult.disruptionValues) {
        return "/disruptions";
      } else if (!scenarioResult.damage) {
        return "/damage";
      } else if (!scenarioResult.selectedTactics.length) {
        return "/tactics";
      } else {
        return "/results";
      }
    },
  },
  // mounted() {
  //   consola.info("index mounted");
  //   const img = this.$refs.logo.querySelector("img");
  //   const newImg = img.cloneNode(true);
  //   newImg.classList.remove("landing__image");
  //   let src = newImg.getAttribute("src");
  //   const parts = src.split("?");
  //   const time = new Date().getTime();
  //   src = parts[0] + "?" + time;
  //   newImg.setAttribute("src", src);
  //   this.$refs.logo.replaceChild(newImg, img);
  //   newImg.classList.add("landing__image");
  //   // eslint-disable-next-line no-void
  //   // void newImg.offsetWidth;
  //   newImg.focus();
  // },
  methods: {
    clearForm() {
      this.$store.dispatch("clear");
      this.$store.commit("setAuthState", "signup");
    },
    setCreateAccount() {
      this.$store.commit("setCreateAccount", true);
    },
  },
};
</script>

<style scoped>
.landing__logo {
  flex-grow: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.landing__image {
  width: 330px;
  margin-top: 54px;
  /* https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/begin */
  background: url(/images/BRC-home-logo-background.svg) no-repeat center;
  background-size: 260px;
}

.landing__header {
  font-size: 46px;
  font-weight: 400;
  margin: 0;
  text-align: center;
}

@media (max-width: 400px) {
  .landing__image {
    width: 260px;
    background-size: 206px;
  }

  .landing__header {
    font-size: 28px;
  }
}

.landing__buttons {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

.landing__login {
  font-size: 46px;
  height: 60px;
  margin-bottom: 36px;
}

.landing__create-account {
  height: 60px;
  font-weight: 500;
}
</style>
